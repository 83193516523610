/**
 * File: styles.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: buttons.scss
 *
 * Option:
 * Author:
 * License:
 *
 **/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/**
 * Variables
 **/
/**
 * Google Fonts styles element
 **/
@import url("https://fonts.googleapis.com/css?family=Oswald:400,500|Rambla:400,700");
/**
 * Styles element
 **/
body {
  margin: 0;
  color: #101010;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.smoothed {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  color: #273239;
  font-family: source-code-pro, Menlo, Consolas, monospace, monospace;
}

pre {
  padding: 1.2rem;
  margin-bottom: 1rem;
  color: #273239;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: auto;
  font-family: source-code-pro, Menlo, Consolas, monospace;
  font-size: 0.8rem;
  white-space: pre;
}

b,
strong {
  color: #101010;
  font-weight: bold;
}

.text-muted {
  color: #6c757d !important;
}

a {
  color: #5584ff;
  background-color: transparent;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #5584ff;
  text-decoration: underline;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
}

.h1,
h1 {
  font-size: 24px;
  line-height: 1;
}
@media screen and (min-width: 576px) {
  .h1,
h1 {
    font-size: 26px;
  }
}
@media screen and (min-width: 768.02px) {
  .h1,
h1 {
    font-size: 36px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 900px) {
  .h1,
h1 {
    font-size: 46px;
  }
}
@media screen and (min-width: 992px) {
  .h1,
h1 {
    font-size: 58px;
    line-height: 1.3;
  }
}
@media screen and (min-width: 1440px) {
  .h1,
h1 {
    font-size: 64px;
  }
}

.h2,
h2 {
  font-size: 22px;
  line-height: 1.15;
}
@media screen and (min-width: 576px) {
  .h2,
h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 768.02px) {
  .h2,
h2 {
    font-size: 25px;
    line-height: 1.2;
  }
}
@media screen and (min-width: 900px) {
  .h2,
h2 {
    font-size: 26px;
  }
}
@media screen and (min-width: 992px) {
  .h2,
h2 {
    font-size: 27px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1440px) {
  .h2,
h2 {
    font-size: 28px;
  }
}

.h3,
h3 {
  font-size: 20px;
  line-height: 1.15;
}
@media screen and (min-width: 576px) {
  .h3,
h3 {
    font-size: 22px;
  }
}
@media screen and (min-width: 768.02px) {
  .h3,
h3 {
    font-size: 23px;
    line-height: 1.15;
  }
}
@media screen and (min-width: 900px) {
  .h3,
h3 {
    font-size: 24px;
  }
}
@media screen and (min-width: 992px) {
  .h3,
h3 {
    font-size: 25px;
    line-height: 1.2;
  }
}
@media screen and (min-width: 1440px) {
  .h3,
h3 {
    font-size: 26px;
  }
}

.h4,
h4 {
  font-size: 18px;
  line-height: 1.3;
}
@media screen and (min-width: 576px) {
  .h4,
h4 {
    font-size: 20px;
  }
}
@media screen and (min-width: 768.02px) {
  .h4,
h4 {
    font-size: 21px;
    line-height: 1.5;
  }
}
@media screen and (min-width: 900px) {
  .h4,
h4 {
    font-size: 22px;
  }
}
@media screen and (min-width: 992px) {
  .h4,
h4 {
    font-size: 23px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1440px) {
  .h4,
h4 {
    font-size: 24px;
  }
}

.h5,
h5 {
  font-size: 16px;
  line-height: 1.3;
}
@media screen and (min-width: 576px) {
  .h5,
h5 {
    font-size: 18px;
  }
}
@media screen and (min-width: 768.02px) {
  .h5,
h5 {
    font-size: 19px;
    line-height: 1.5;
  }
}
@media screen and (min-width: 900px) {
  .h5,
h5 {
    font-size: 20px;
  }
}
@media screen and (min-width: 992px) {
  .h5,
h5 {
    font-size: 21px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1440px) {
  .h5,
h5 {
    font-size: 22px;
  }
}

.h6,
h6 {
  font-size: 16px;
  line-height: 1.2;
}
@media screen and (min-width: 576px) {
  .h6,
h6 {
    font-size: 18px;
  }
}
@media screen and (min-width: 768.02px) {
  .h6,
h6 {
    font-size: 19px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 900px) {
  .h6,
h6 {
    font-size: 20px;
  }
}
@media screen and (min-width: 992px) {
  .h6,
h6 {
    font-size: 21px;
    line-height: 1.3;
  }
}
@media screen and (min-width: 1440px) {
  .h6,
h6 {
    font-size: 22px;
  }
}

p {
  font-family: inherit;
  font-size: 16px;
  line-height: 1.2;
}
@media screen and (min-width: 576px) {
  p {
    font-size: 16px;
  }
}
@media screen and (min-width: 768.02px) {
  p {
    font-size: 17px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 900px) {
  p {
    font-size: 18px;
  }
}
@media screen and (min-width: 992px) {
  p {
    font-size: 20px;
    line-height: 1.3;
  }
}
@media screen and (min-width: 1440px) {
  p {
    font-size: 22px;
  }
}

ul,
li {
  font-family: inherit;
  font-size: 16px;
  line-height: 1.2;
}
@media screen and (min-width: 576px) {
  ul,
li {
    font-size: 16px;
  }
}
@media screen and (min-width: 768.02px) {
  ul,
li {
    font-size: 17px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 900px) {
  ul,
li {
    font-size: 18px;
  }
}
@media screen and (min-width: 992px) {
  ul,
li {
    font-size: 20px;
    line-height: 1.3;
  }
}
@media screen and (min-width: 1440px) {
  ul,
li {
    font-size: 22px;
  }
}

.content-section h3,
.content-section h4,
.content-section h5,
.content-section p,
.content-section span {
  color: #070f44;
}
.content-section .content-title {
  margin-bottom: 1rem;
}
.content-section p {
  margin: 0 0 1.2rem;
}
.content-section p:last-child {
  margin-bottom: 0;
}
.content-section ul {
  list-style: none;
  padding: 0;
  color: #070f44;
  /*ul {
      //padding-left: 1rem;

      list-style: none;
      li {

      }
      //i {
      //  padding-right: 0.25rem;
      //
      //  color: $element-site-primary-color;
      //}
  */
}
.content-section ul li {
  padding-bottom: 0.5rem;
}
.content-section ul li svg {
  margin-right: 0.5rem;
  color: #5584ff;
}
.content-section .content-list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.content-section .content-list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.content-section .accordion-button {
  font-family: inherit;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
}
@media screen and (min-width: 576px) {
  .content-section .accordion-button {
    font-size: 16px;
  }
}
@media screen and (min-width: 768.02px) {
  .content-section .accordion-button {
    font-size: 17px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 900px) {
  .content-section .accordion-button {
    font-size: 18px;
  }
}
@media screen and (min-width: 992px) {
  .content-section .accordion-button {
    font-size: 20px;
    line-height: 1.3;
  }
}
@media screen and (min-width: 1440px) {
  .content-section .accordion-button {
    font-size: 22px;
  }
}

/**
 * Font-face
 **/
::selection {
  color: #6c757d;
  background: rgba(0, 0, 0, 0.1);
}

/*
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #101010;
}

::-webkit-scrollbar-thumb:hover {
  background: #101010;
}
*/
/**
 * File: section31.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Extend element
 **/
.btn-get-started {
  color: #fff;
  background: #5584ff;
  text-transform: uppercase;
}

/**
 *	Style Modal Close Button
 **/
/**
 * Styles
 **/
.btn-new {
  display: inline-block;
  padding: 1.2rem 3rem;
  color: #fff;
  background: #482cbf;
  background: linear-gradient(45deg, #482cbf 0%, #6ac6f0 100%);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  border: none;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 700;
  font-size: 14px;
  transition: 0.3s;
}
.btn-new:focus, .btn-new:hover {
  text-decoration: none;
  outline: none;
}
.btn-new:hover {
  animation-delay: 0.8s;
  animation-name: fadeInUp;
  color: #fff;
  box-shadow: 0 25px 50px -20px rgba(221, 158, 158, 0.6);
  transform: translateY(-5px);
}

.btn-get-started {
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 1px;
  border-radius: 3px;
  margin: 10px;
}

.default-btn {
  font-size: 16px;
  color: #fff;
  padding: 15px 30px;
  line-height: 1;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-color: #d80650;
}
.default-btn.active {
  margin-left: 20px;
  color: #0e0129;
  background-color: #fff;
}

/**
 * File: videomodal.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * Variables
 **/
@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}
.modal-content {
  background-color: rgba(0, 0, 0, 0.5);
}

.video-modal .modal-video-body .modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.modal-video-effect-exit {
  opacity: 0;
}
.modal-video-effect-exit .modal-video-movie-wrap {
  transform: translate(0, 100px);
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}
@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}
.modal-video-close-btn:after {
  transform: rotate(-45deg);
}
.modal-video-close-btn:before, .modal-video-close-btn:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}
/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Extend element
 **/
.content-grid {
  text-align: left !important;
}
.content-grid .content-inner-item {
  display: flex;
  margin-bottom: 2rem;
}
.content-grid .last-line.content-inner-item {
  margin-bottom: 0;
}
.content-grid .content-title {
  position: relative;
}
.content-grid .content-text {
  margin-bottom: 0;
}

/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.top-menu-navbar .top-menu-navbar-nav .nav-link,
.top-menu-navbar .footer-menu-navbar-nav .nav-link,
.footer-menu-navbar .top-menu-navbar-nav .nav-link,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link {
  position: relative;
  color: #f9f9f9;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  outline: none;
}

.top-menu-navbar {
  padding: 0;
}
.top-menu-navbar .navbar-brand {
  padding: 0;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
}
.top-menu-navbar .top-menu-navbar-icon {
  margin-right: 0.25rem;
}
.top-menu-navbar .top-menu-navbar-nav .dropdown-menu {
  padding: 0.5rem 1rem;
  margin-top: 0.825rem;
  background: rgba(0, 0, 0, 0.8);
}
.top-menu-navbar .top-menu-navbar-nav .dropdown-menu .nav-link {
  margin: 0;
  background-color: transparent;
  text-align: left;
}

.footer-menu-navbar-nav {
  justify-content: center;
}
.footer-menu-navbar-nav .nav-link:not(:first-child) {
  margin-left: 1rem;
}

.top-menu-navbar-nav .nav-link {
  margin-left: 1rem;
}

.top-menu-navbar .top-menu-navbar-nav .nav-link,
.top-menu-navbar .footer-menu-navbar-nav .nav-link,
.footer-menu-navbar .top-menu-navbar-nav .nav-link,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link {
  display: inline-block;
}
.top-menu-navbar .top-menu-navbar-nav .nav-link:before,
.top-menu-navbar .footer-menu-navbar-nav .nav-link:before,
.footer-menu-navbar .top-menu-navbar-nav .nav-link:before,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #5584ff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.top-menu-navbar .top-menu-navbar-nav .nav-link:focus,
.top-menu-navbar .footer-menu-navbar-nav .nav-link:focus,
.footer-menu-navbar .top-menu-navbar-nav .nav-link:focus,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link:focus {
  color: #fafafa;
}
.top-menu-navbar .top-menu-navbar-nav .nav-link:hover,
.top-menu-navbar .footer-menu-navbar-nav .nav-link:hover,
.footer-menu-navbar .top-menu-navbar-nav .nav-link:hover,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link:hover {
  color: #fafafa;
}
.top-menu-navbar .top-menu-navbar-nav .nav-link:hover:before,
.top-menu-navbar .footer-menu-navbar-nav .nav-link:hover:before,
.footer-menu-navbar .top-menu-navbar-nav .nav-link:hover:before,
.footer-menu-navbar .footer-menu-navbar-nav .nav-link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.top-menu {
  padding: 0.8rem 0;
  background: rgba(0, 0, 0, 0.8);
}
.top-menu.scrolled {
  background: rgba(0, 0, 0, 0.8);
}
.top-menu .top-menu-navbar-nav {
  margin-left: auto;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

/*
.top-menu-nav a,
.top-menu-nav a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.top-menu99 {
  padding: 0 0 14px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;

  background: rgba(55, 64, 85, 0.9);
  padding: 15px 0;

  &.header-scrolled {
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.5s;
  }
}
*/
.main-page-notfound {
  position: relative;
  height: 100vh;
  background-color: #222;
}
.main-page-notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}
.notfound .notfound-404 {
  display: block;
  margin-bottom: 2rem;
  line-height: 153px;
}
.notfound .notfound-404 h1 {
  color: #222;
  font-size: 220px;
  letter-spacing: 10px;
  margin: 0;
  font-weight: 700;
  text-shadow: 2px 2px 0 #c9c9c9, -2px -2px 0 #c9c9c9;
}
.notfound .notfound-404 h1 > span {
  text-shadow: 2px 2px 0 #ffab00, -2px -2px 0 #ffab00, 0 0 8px #ff8700;
}
.notfound h2,
.notfound h3 {
  color: #c9c9c9;
}
.notfound p {
  color: #c9c9c9;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1rem;
}
.notfound a {
  font-size: 0.8rem;
  text-decoration: none;
  text-transform: uppercase;
  background: 0 0;
  color: #c9c9c9;
  border: 2px solid #c9c9c9;
  display: inline-block;
  padding: 10px 25px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.notfound a:hover {
  color: #ffab00;
  border-color: #ffab00;
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 122px;
    line-height: 122px;
  }
  .notfound .notfound-404 h1 {
    font-size: 122px;
  }
}
/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
ul.social-links {
  display: inline-block;
  text-align: center !important;
}

.social-links li {
  display: inline-block;
  margin-right: 1rem;
}
.social-links a {
  display: flex;
  justify-content: center;
  width: 46px;
  height: 46px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
}
.social-links a:focus, .social-links a:hover {
  color: #5584ff;
  border-color: #5584ff;
  text-decoration: underline;
}
.social-links a:active, .social-links a:hover {
  outline: 0;
}
.social-links a svg {
  margin: auto;
  height: 1.8rem;
  width: 1.8rem;
}

/**
 * File: articles.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.section-image-title {
  position: relative;
  width: 100%;
  /* for IE 6 */
  padding-bottom: 2rem;
  text-align: center;
  overflow: hidden;
}
.section-image-title .feature-img {
  height: 100%;
  overflow: hidden;
}
.section-image-title .feature-img img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  filter: grayscale(50%);
}
.section-image-title .section-image-title-header {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  backdrop-filter: blur(10px);
}
.section-image-title .section-image-title-header > h1 {
  margin: 0;
  color: white;
  text-shadow: 0 1px 0 black;
}

.blog-article h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.blog-article ol > h2::before {
  padding-right: 0.3em;
}
.blog-article p {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: justify;
}
.blog-article p span {
  font-weight: bold;
}
.blog-article .blog-article-cmd-list {
  list-style-type: none;
}
.blog-article .blog-article-image img {
  margin-left: auto;
  margin-right: auto;
}

/**
 * Styles control
 **/
.blog-article-pagination-nav {
  position: relative;
  padding: 20px 0 40px;
  margin-top: 20px;
}
.blog-article-pagination-nav::before {
  position: absolute;
  left: 50%;
  top: 20%;
  height: 70%;
  width: 1px;
  transform: translate(-50%, -20%);
  background-color: #6c757d;
  opacity: 0.25;
  content: "";
}
.blog-article-pagination-nav .pagination-nav-item-next,
.blog-article-pagination-nav .pagination-nav-item-prev {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.blog-article-pagination-nav .pagination-nav-item-next .pagination-nav-item-link,
.blog-article-pagination-nav .pagination-nav-item-prev .pagination-nav-item-link {
  margin-bottom: 0.8rem;
  color: #6c757d;
  text-decoration: none !important;
  /* display: flex; */
  /* align-items: center; */
  /* flex-direction: row; */
  /* font-size: 15pt; */
  /* font-family: var(--font-din); */
  font-weight: 400;
}
.blog-article-pagination-nav .pagination-nav-item-next .pagination-nav-item-title,
.blog-article-pagination-nav .pagination-nav-item-prev .pagination-nav-item-title {
  color: #101010;
  font-weight: 600;
}
.blog-article-pagination-nav .pagination-nav-item-next .pagination-nav-lable {
  margin-left: auto;
  margin-right: 0.5rem;
}
.blog-article-pagination-nav .pagination-nav-item-prev .pagination-nav-lable {
  margin-left: 0.5rem;
  margin-right: auto;
}
.blog-article-pagination-nav .pagination-nav-item-next {
  text-align: right;
}
.blog-article-pagination-nav .pagination-nav-item-next .pagination-nav-item-title {
  margin-left: auto;
}

/**
 * File: articles.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.article-container,
.article-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768.02px) {
  .article-container,
.article-wrapper {
    overflow: hidden;
  }
}

/**
 * Styles
 **/
.post {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .post {
    padding-top: 2.125rem;
    padding-bottom: 2.125rem;
  }
}

.post .image-container {
  margin-bottom: 4rem;
}
.post .image-container img {
  border-radius: 8px;
}
.post h2 {
  margin-bottom: 1.5rem;
}
.post .read-more {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.blog-info {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #a7b0b4;
  text-transform: uppercase;
}
.blog-info .blog-meta {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0.8rem;
}
.blog-info .blog-meta li {
  display: inline-block;
}
.blog-info * + .tags {
  margin-left: 30px;
}
.blog-info .blog-meta-publish-date {
  font-size: 0.8rem;
}
.blog-info .blog-meta-category {
  font-size: 0.8rem;
}
.blog-info .tags .post-tag {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 40px;
}
.blog-info .post-tag {
  display: inline-block;
  padding: 0.25rem 1rem;
  color: #a7b0b4;
  background: #eceff1;
  text-transform: uppercase;
}
.blog-info .post-tag:hover {
  color: #fff;
  background: #14a5eb;
  text-decoration: none;
}
.blog-info .post-tag + .post-tag {
  margin-left: 0.5rem;
}
.blog-info .tags-icon {
  margin-right: 1rem;
}

/**
 * Styles
 **/
.blog-article-note {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 20px;
  border-top: 2px solid #5584ff;
  border-bottom: 2px solid #5584ff;
}
.blog-article-note .blog-article-note-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 96px;
  height: 96px;
  line-height: 104px;
  vertical-align: middle;
  border: 2px solid #5584ff;
  text-align: center;
  background-color: #5584ff;
  border-radius: 3px;
}
.blog-article-note .blog-article-note-text {
  margin-left: 20px;
  width: 84%;
}

.section-offer-contract-policy, .section-privacy-policy {
  background: #ededed url(../images/shattered1.png);
  padding: 86px 20px 40px;
}

.section-offer-contract-policy .container, .section-privacy-policy .container {
  background: #fff;
  border-radius: 2px;
  padding: 45px;
  margin: 0 auto;
}

/**
 *	Typography Style
 **/
.offer-contract-policy ol {
  list-style: none;
  counter-reset: li;
  padding-left: 1rem;
  text-align: justify;
}
.offer-contract-policy ol > li:before {
  counter-increment: li;
  content: counters(li, ".") ". ";
}
.offer-contract-policy ol ul {
  list-style-type: disc;
  margin: 0;
}
.offer-contract-policy ol li,
.offer-contract-policy ul li {
  padding: 1.25rem 0 0;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: -0.03125rem;
}
.offer-contract-policy ol li ol,
.offer-contract-policy ol li ul,
.offer-contract-policy ul li ol,
.offer-contract-policy ul li ul {
  padding-top: 0.3125rem;
}
.offer-contract-policy ol li ol li,
.offer-contract-policy ol li ul li,
.offer-contract-policy ul li ol li,
.offer-contract-policy ul li ul li {
  padding: 0;
  line-height: 1.5rem;
  letter-spacing: -0.03125rem;
}

/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.site-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 992px) {
  .site-section {
    padding-top: 5.25rem;
    padding-bottom: 5.25rem;
  }
}

.section-about,
.section-services,
.section-skills,
.section-brands,
.section-letscontact,
.section-contact2,
.section-blog-preview,
.section-section32,
.section-section33 {
  padding-top: 260px;
}

.course-program {
  background-color: #f1f3ff !important;
}

.section-border {
  border-bottom: 1px solid #f3f3ed;
}

.section-bg {
  position: relative;
  padding: 120px 0;
  color: #fff;
}
.section-bg h1,
.section-bg h2,
.section-bg h3,
.section-bg h4,
.section-bg h5,
.section-bg h6,
.section-bg p,
.section-bg span {
  color: #fff;
}
.section-bg:before {
  content: "";
  background: #1b1b1b;
  position: absolute;
  bottom: 60px;
  top: 60px;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
}

img.center {
  display: block;
  margin: 0 auto;
}

.card {
  padding: 0;
  border: none;
  margin-bottom: 50px;
  box-shadow: 0 2px 14px 0 rgba(47, 60, 83, 0.16);
}
.card .card-body {
  padding: 30px 20px;
}
.card .card-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.card .card-text {
  font-size: 12px;
  line-height: 1.4;
}
.card .card-list {
  font-size: 12px;
  padding-left: 15px;
}

/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.main-footer {
  color: white;
  background-color: #222;
}
.main-footer .footer-top {
  padding: 80px 0;
  text-align: center;
}
.main-footer .footer-text {
  color: white;
}
.main-footer .footer-bottom {
  padding-top: 40px;
  padding-bottom: 40px;
}
.main-footer .copyright {
  text-align: center;
}
.main-footer .credits {
  text-align: center;
  font-size: 13px;
  padding-top: 5px;
}

/**
 * File: typicalsections.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.page-title {
  padding-top: 5rem;
  color: #101010;
  text-align: center;
}
.page-title .section-bg {
  color: white;
}

.content-section .section-title {
  margin-bottom: 2.8rem;
  color: #101010;
  text-align: center;
}
.content-section .section-title.section-bg {
  color: white;
}
.content-section .section-title.section-feature h2 {
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  font-weight: bold;
}
.content-section .section-title.section-feature h2::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  width: 122px;
  height: 66px;
  background: url(../images/section-title-bg.png) no-repeat;
  transform: translate(-50%, -50%);
}

/**
 * Styles
 **/
.content-grid .content-body {
  display: inline-block;
}
.content-grid .content-title-icon {
  margin-right: 1rem;
  color: #5584ff;
}
@media (min-width: 992px) {
  .content-grid .content-title-icon {
    margin-right: 1.5rem;
    font-size: 2rem;
  }
}
/**
 * Styles
 **/
.content-two-column .content-inner-item {
  margin-bottom: 1rem;
  display: flex;
}
.content-two-column .content-inner-item.last-line {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .content-two-column .content-inner-item {
    margin-bottom: 2rem;
  }
}
.content-two-column .content-body {
  float: left;
}
.content-two-column .content-title-icon {
  margin-right: 0.5rem;
  color: #5584ff;
  font-size: 3rem;
}
@media (min-width: 992px) {
  .content-two-column .content-title-icon {
    margin-right: 1.5rem;
  }
}
.content-two-column .content-subtitle {
  margin-bottom: 0.8rem;
}
/**
 * Styles
 **/
.content-chess .image-box {
  display: block;
  margin: auto;
  overflow: hidden;
}
.content-chess .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/**
 * Styles
 **/
/**
 * Styles
 **/
.content-testimonials .image-box {
  display: block;
  margin: auto;
  overflow: hidden;
  width: 150px;
  height: 150px;
  text-align: center;
}
.content-testimonials .image-box img {
  width: 100%;
  height: 100%;
  border-color: #5584ff;
  border-width: 2px;
  border-style: solid;
  object-fit: cover;
  vertical-align: middle;
}
@media (min-width: 768.02px) {
  .content-testimonials .image-box {
    width: 180px;
    height: 180px;
  }
}
@media (min-width: 992px) {
  .content-testimonials .image-box {
    width: 240px;
    height: 240px;
  }
}
.content-testimonials .content-body {
  margin-bottom: 4rem;
}
@media (min-width: 768.02px) {
  .content-testimonials .content-body {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
.content-testimonials .content-title {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
}
.content-testimonials .content-subtitle {
  margin-bottom: 1rem;
  color: #6c757d;
}
.content-testimonials .content-text {
  margin: 20px auto 0;
  line-height: 2;
}
.content-testimonials .quote-icon {
  display: inline-block;
  position: relative;
  color: #5584ff;
  line-height: 1rem;
  text-rendering: auto;
  text-transform: none;
}
.content-testimonials .quote-icon-left {
  left: -5px;
  top: -0.5rem;
}
.content-testimonials .quote-icon-right {
  right: -5px;
  top: -0.5rem;
}

/**
 * Styles
 **/
/*
@media screen and (min-width: 960px)
.feature-wrap {
        min-height: 310px;
        padding: 40px 40px;
}
.feature-wrap {
        background-color: #fff;
        padding: 60px 45px;
        margin-bottom: 30px;
        border: 1px solid #e9e9e9;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        transition: 0.3s;
        text-align: center;
}*/
/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * File: course1.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Extend element
 **/
.content-grid {
  text-align: left !important;
}
.content-grid .content-inner-item {
  display: flex;
  margin-bottom: 2rem;
}
.content-grid .last-line.content-inner-item {
  margin-bottom: 0;
}
.content-grid .content-title {
  position: relative;
}
.content-grid .content-text {
  margin-bottom: 0;
}

/**
 * Variables
 **/
/**
 * Styles
 **/
.section-event-registration1 {
  padding-top: 6rem;
  padding-bottom: 4rem;
}
.section-event-registration1 h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.section-event-registration2 .card {
  align-content: center;
  align-items: center;
  background-color: #5287c8;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  padding: 20px 20px 20px 20px;
  border-radius: 1.8rem 1.8rem 1.8rem 1.8rem 1.8rem;
}
.section-event-registration2 .card .title {
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5em;
}
.section-event-registration2 .card .button {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  width: auto;
  fill: #5287c8;
  color: #5287c8;
  background-color: #ffffff;
  border-radius: 25px 25px 25px 25px;
  box-shadow: none;
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s;
}
.section-event-registration2 .card .button:hover {
  transform: scale(1.1);
}
.section-event-registration2.background-image {
  background-image: url(../images/unsplasf_bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.section-event-registration3 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.section-event-registration3 button {
  margin-right: auto;
  margin-left: auto;
}

/**
 * File: course1.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.section-course5-price, .section-course1-price {
  background: url(../images/testimonials-bg.webp) no-repeat;
  background-position: center center;
  background-size: cover;
}
.section-course5-price h2, .section-course1-price h2,
.section-course5-price h3,
.section-course1-price h3,
.section-course5-price h4,
.section-course1-price h4,
.section-course5-price h5,
.section-course1-price h5,
.section-course5-price h6,
.section-course1-price h6,
.section-course5-price p,
.section-course1-price p,
.section-course5-price span,
.section-course1-price span {
  color: white;
}
.section-course5-price .btn-new, .section-course1-price .btn-new {
  display: block;
  margin-bottom: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}
.section-course5-price .content-body-price .content-inner-item, .section-course1-price .content-body-price .content-inner-item {
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-bottom: 2px solid #5584ff;
}
.section-course5-price .content-body-description .content-inner-item, .section-course1-price .content-body-description .content-inner-item {
  margin-bottom: 1rem;
  padding: 1.5rem;
  color: white;
}
/**
 * File: course1.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Extend element
 **/
.content-grid {
  text-align: left !important;
}
.content-grid .content-inner-item {
  display: flex;
  margin-bottom: 2rem;
}
.content-grid .last-line.content-inner-item {
  margin-bottom: 0;
}
.content-grid .content-title {
  position: relative;
}
.content-grid .content-text {
  margin-bottom: 0;
}

/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Styles
 **/
.section-course1-section1 .content-title-icon img {
  width: 24px;
  height: 24px;
}
@media (min-width: 992px) {
  .section-course1-section1 .content-title-icon img {
    width: 48px;
    height: 48px;
  }
}
.section-course1-section1 .image-box img {
  border-radius: 0.5rem;
}

/**
 * Styles
 **/
.section-course1-section2 .course-chess-content .image-box {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.section-course1-section2 .course-chess-content .image-box img {
  border-radius: 0.5rem;
}
.section-course1-section2 .course-chess-content .odd .image-box {
  float: right;
}
.section-course1-section2 .course-chess-content :not(.odd) .image-box {
  float: left;
}

/**
 * Styles
 **/
.section-course1-section5 .img-responsive {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: grayscale(75%);
}
.section-course1-section5 .carousel.slide {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.section-course1-section5 .carousel-item-box {
  position: relative;
}
.section-course1-section5 .carousel-indicators {
  margin-bottom: 0;
}
.section-course1-section5 .carousel-item-caption {
  position: absolute;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 5%;
  z-index: 1;
}
.section-course1-section5 .carousel-item-caption a {
  color: #0a58ca;
}
.section-course1-section5 .carousel-item-caption a:hover {
  text-decoration: none;
}
.section-course1-section5 .carousel-control-next,
.section-course1-section5 .carousel-control-prev {
  width: 3%;
}
.section-course1-section5 .carousel-control-next-icon,
.section-course1-section5 .carousel-control-prev-icon {
  height: 3rem;
}
.section-course1-section5 .carousel-inner .active {
  left: 0;
}
.section-course1-section5 .carousel-inner > .active {
  display: block;
  left: 0;
}
.section-course1-section5 .carousel-inner > .carousel-item {
  transition: 0.6s ease-in-out left;
}
.section-course1-section5 .carousel-inner.carousel-item {
  display: none;
  position: relative;
}

/**
 * Styles
 **/
.section-course1-section6 .content-wrapper {
  position: relative;
}
.section-course1-section6 .content-wrapper .image-box {
  max-width: 810px;
  position: relative;
}
.section-course1-section6 .content-wrapper .image-box img::after {
  filter: contrast(0) grayscale(0) brightness(2);
  left: 490px;
  bottom: -70px;
}
@media only screen and (min-width: 992px) {
  .section-course1-section6 .content-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: calc(40% - 140px);
    background-color: #0d6db4;
  }
}

@media only screen and (min-width: 992px) {
  .section-course1-section6 .content-wrapper:not(.odd)::before {
    left: 0;
  }
}

@media only screen and (min-width: 992px) {
  .section-course1-section6 .content-wrapper.odd::before {
    right: 0;
  }
}

/**
 * Styles
 **/
.section-course1-countup {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #f4f9fc;
}
.section-course1-countup .count-item {
  padding: 2.5rem;
  width: 100%;
}
.section-course1-countup .count-item span {
  display: block;
  color: #2f4d5a;
  font-size: 48px;
  font-weight: 700;
  line-height: 40px;
}
@media (min-width: 992px) {
  .section-course1-countup .count-item span {
    font-size: 60px;
  }
}
.section-course1-countup .count-item p {
  padding: 1rem 0 0 0;
}

/**
 * Styles
 **/
.section-course1-section7 .card-container {
  perspective: 1000px;
  height: 250px;
}
.section-course1-section7 .card-container:hover .card-flip {
  transform: rotateY(180deg);
}
.section-course1-section7 .card {
  margin-bottom: 0;
  justify-content: center;
  background-color: #f4f9fc;
}
.section-course1-section7 .card .content-title {
  margin-bottom: 0;
}
.section-course1-section7 .card-flip {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.section-course1-section7 .card-flip-front,
.section-course1-section7 .card-flip-back {
  position: absolute;
  width: 100%;
  padding: 1rem;
  backface-visibility: hidden;
}
.section-course1-section7 .card-flip-back {
  transform: rotateY(180deg);
}

/**
 * Styles
 **/
.gallery .block:hover .gallery-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.gallery .gallery-overlay {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  top: 15px;
  background: rgba(255, 255, 255, 0.85);
  text-decoration: none;
  color: inherit;
  transform: scale(0.5);
  transition: all 0.3s;
  z-index: 2;
  opacity: 0;
}

/**
 * File: course1.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Extend element
 **/
.content-grid {
  text-align: left !important;
}
.content-grid .content-inner-item {
  display: flex;
  margin-bottom: 2rem;
}
.content-grid .last-line.content-inner-item {
  margin-bottom: 0;
}
.content-grid .content-title {
  position: relative;
}
.content-grid .content-text {
  margin-bottom: 0;
}

/**
 * Variables
 **/
/**
 * Styles
 **/
/**
 * Styles
 **/
.section-course5-testimonials .img-responsive {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: grayscale(75%);
}
.section-course5-testimonials .carousel.slide {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.section-course5-testimonials .carousel-item-box {
  position: relative;
}
.section-course5-testimonials .carousel-indicators {
  margin-bottom: 0;
}
.section-course5-testimonials .carousel-item-caption {
  position: absolute;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 5%;
  z-index: 1;
}
.section-course5-testimonials .carousel-item-caption a {
  color: #0a58ca;
}
.section-course5-testimonials .carousel-item-caption a:hover {
  text-decoration: none;
}
.section-course5-testimonials .carousel-control-next,
.section-course5-testimonials .carousel-control-prev {
  width: 3%;
}
.section-course5-testimonials .carousel-control-next-icon,
.section-course5-testimonials .carousel-control-prev-icon {
  height: 3rem;
}
.section-course5-testimonials .carousel-inner .active {
  left: 0;
}
.section-course5-testimonials .carousel-inner > .active {
  display: block;
  left: 0;
}
.section-course5-testimonials .carousel-inner > .carousel-item {
  transition: 0.6s ease-in-out left;
}
.section-course5-testimonials .carousel-inner.carousel-item {
  display: none;
  position: relative;
}

/**
 * Styles
 **/
.section-course5-graduation-project .content-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-course5-graduation-project .content-image .image-box {
  overflow: hidden;
}
.section-course5-graduation-project .content-image .image-box img {
  object-fit: cover;
}

.section-course5-section4 {
  text-align: center;
  display: in;
}
.section-course5-section4 .content-number {
  color: #5584ff;
  font-size: 48px;
}
@media (min-width: 768.02px) {
  .section-course5-section4 .content-number {
    font-size: 98px;
    font-weight: 900;
  }
}
@media (min-width: 992px) {
  .section-course5-section4 .content-number {
    font-size: 120px;
    margin-right: 2rem;
  }
}
@media (min-width: 992px) {
  .section-course5-section4 .content-body {
    vertical-align: top;
    max-width: 65%;
  }
}
/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.section-hero {
  position: relative;
  z-index: 1;
  height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-hero .banner-text {
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.section-hero .banner-text h1,
.section-hero .banner-text h2,
.section-hero .banner-text h3,
.section-hero .banner-text p,
.section-hero .banner-text span {
  color: #fff;
}
.section-hero .banner-text h1 {
  margin-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: 400;
}
.section-hero .banner-text h2,
.section-hero .banner-text h3 {
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 400;
}
.section-hero .banner-text span {
  display: block;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
}
.section-hero .banner-text p {
  margin-bottom: 4rem;
  font-size: 16px;
}
@media (min-width: 768.02px) {
  .section-hero .banner-text h1 {
    font-size: 3.8rem;
  }
}
@media (min-width: 1200px) {
  .section-hero .banner-text h1 {
    font-size: 4.5rem;
  }
}

.section-subhero {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: black;
}
.section-subhero .banner-text {
  margin: 2rem 1rem;
  color: #ffffff;
}
.section-subhero .banner-text p {
  margin-bottom: 0;
  font-size: 18px;
}
.section-subhero .banner-text p span {
  font-weight: bolder;
}
.section-subhero .banner-text img {
  float: left;
  margin-right: 1rem;
}

.section-hero .hero-video-btn {
  margin-top: 2rem;
  margin-bottom: auto;
}
@media (min-width: 768.02px) {
  .section-hero .hero-video-btn {
    margin-top: auto;
  }
}
.section-hero .video-btn {
  margin: auto;
  display: block;
}

.section-hero .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 89px;
  text-align: center;
  border-radius: 100%;
  color: #d80650;
  position: relative;
  top: 3px;
  z-index: 1;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
  cursor: pointer;
}
.section-hero .video-btn i {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 0;
  left: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-hero .video-btn i {
    font-size: 35px;
    left: 3px;
  }
}

.section-hero .video-btn:after {
  animation: ripple 1.6s ease-out infinite;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 100%;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
}
.section-hero .video-btn:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 100%;
  background-image: linear-gradient(to bottom right, #d2044d, #ff5e68);
}

@keyframes ripple {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  to {
    opacity: 0;
    transform: scale(2.5);
  }
}
.section-hero-course5 #video-bg1 {
  position: absolute;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  left: 0px;
  top: 0px;
  overflow: hidden;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 2000ms;
}
.section-hero-course5 #video-bg1 video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}
.section-hero-course5 #video-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
  background-size: cover;
}
.section-hero-course5 #video-bg > video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}
@supports (object-fit: cover) {
  .section-hero-course5 #video-bg > video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.section-hero-course5 .banner-text2 {
  position: absolute;
  top: 40%;
  width: 100%;
  z-index: 10;
}
.section-hero-course5 .bg-overlay1 {
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/**
 * Styles lines animation
 **/
.lines {
  display: none;
}
@media (min-width: 992px) {
  .lines {
    display: block;
  }
}

.lines,
.lines .line {
  position: absolute;
  top: 0;
  height: 100%;
}

.lines {
  left: 0;
  right: 0;
  margin: auto;
  width: 90vw;
  z-index: -1;
}
.lines .line {
  width: 2px;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  animation: moveLeftBounces-one 20s linear infinite;
}
.lines .line:first-child {
  margin-left: -25%;
}
.lines .line:first-child::after {
  animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-left: 25%;
}
.lines .line:nth-child(3)::after {
  animation-delay: 1.5s;
}

@keyframes moveLeftBounces-one {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}
/**
 * Styles old styles
 **/
.section-hero.banner-item-bg-1 {
  background-image: url(../images/home1-banner.jpg);
}

.section-hero.banner-item-bg-2 {
  background-image: url(../images/home2-banner.jpg);
}

.section-hero.banner-item-bg-3 {
  background-image: url(../images/home2-banner.jpg);
}
.section-hero.banner-item-bg-3 .filter {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  height: 100vh;
}

.banner-carousel {
  z-index: 1;
  overflow: hidden;
  white-space: pre-line;
}
.banner-carousel h2 {
  margin-bottom: 2rem;
  color: #fff;
}
.banner-carousel-caption {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1.75rem;
}
.banner-carousel-caption h1,
.banner-carousel-caption h2,
.banner-carousel-caption h3,
.banner-carousel-caption p,
.banner-carousel-caption span {
  color: #fff;
}
.banner-carousel-caption h3 {
  margin-bottom: 35px;
}
.banner-carousel .carousel .carousel-item .carousel-caption {
  padding-left: 3rem;
  padding-right: 3rem;
}

/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/**
 * Typography Media queries
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.wrapper-contact {
  position: relative;
  background-image: url(../images/network-4851119_1920.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.wrapper-contact .bg-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.wrapper-contact .page-title {
  color: white;
}

.section-contact .form-check-label-text {
  color: white;
}
.section-contact .form-check .form-check-input {
  float: none;
}
.section-contact .form-check .form-check-label {
  margin-left: 1rem;
}
.section-contact .message-success {
  margin-top: 2rem;
}
.section-contact .message-success p {
  text-align: center;
  color: white;
}
.section-contact .section-title h1,
.section-contact .section-title h2,
.section-contact .page-title h1,
.section-contact .page-title h2 {
  color: white;
}
.section-contact p {
  color: white;
}

/**
  * Variables
  **/
/**
  * Styles
  **/
.carousel-controlled .carousel-fade {
  background-color: black;
}